/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import { useQuery, useMutation } from 'react-query';
import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  faChevronLeft, faFileArrowDown, faCircle,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { getRegistreList, postRegistryExport, getExportStatus } from '../../services/registre';
import { getInputsToComplete } from '../../services/inputs';
import { getBuildingsList } from '../../services/structures';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import useAppContext from '../../store/useAppContext';
import styles from './Registre.module.css';
import Table from '../../components/molecules/Table/Table';
import cn from '../../utils/cn';
import Permissions from '../../components/molecules/Permissions/Permissions';
import { TRAINING_NAMES, TRAINING_TYPES } from '../../utils/constant';

function Registre() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [buildingId, setBuildingId] = React.useState();
  const [buildings, setBuildings] = React.useState();
  const [Registres, setRegistres] = React.useState([]);
  const [emails, setEmails] = React.useState(context?.user.email || '');
  const [openModal, setOpenModal] = useState(false);
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
      borderTop: '2rem solid var(--color-primary-600)',
      paddingTop: '0.5rem',
      textAlign: 'center',
    },
  };

  const getRegistreListQuery = useQuery(['RegistreList', buildingId], () => getRegistreList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }), {
    enabled: buildingId !== undefined,
    onSuccess: (data) => {
      setRegistres(data);
    },
  });

  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setBuildings(data?.data?.buildings);
      if (data?.data?.buildings?.length > 0) {
        setBuildingId(data?.data?.buildings[0]?.id);
      }
    },
  });

  const getInputsToCompleteQuery = useQuery(['inputs', buildingId], () => getInputsToComplete({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }));

  const handleBuilding = (id) => {
    setBuildingId(id);
  };
  const docfile = (id, fileName, type) => {
    if (type === 'report') {
      window.open(`${process.env.REACT_APP_API_URL}reports/download-report/${id}/${fileName}`, '_blank');
    } else if (type === 'document') {
      window.open(`${process.env.REACT_APP_API_URL}document-base-items/download-document/${id}/${fileName}`, '_blank');
    } else if (type === 'training') {
      window.open(`${process.env.REACT_APP_API_URL}trainings/download-document/${id}/${fileName}`, '_blank');
    } else {
      const building = buildings.find((data) => data.id === id);
      window.open(`${process.env.REACT_APP_API_URL}myregistry/export/${id}/${building.token}`, '_blank');
    }
  };
  // Call api to schedure registry export
  const getSchedureRegistryExport = useMutation(postRegistryExport, {
    onSuccess: () => {
      navigate('/registre');
      setOpenModal(false);
      toast.success(t('registry.SCHEDULE_EXPORT_SUCCESS'));
    },
    onError: () => {
      toast.error(t('registry.SCHEDULE_EXPORT_FAILED'));
    },
  });
  const scheduleNewExport = (id, receiverEmail) => {
    const building = buildings.find((data) => data.id === id);
    const param = {
      buildingId: building.id,
      token: building.token,
      emails: receiverEmail,
    };
    getSchedureRegistryExport.mutate(param);
  };
  const handleDownloadClick = () => {
    setOpenModal(true);
  };
  const handleSubmitExportDemand = (e) => {
    e.preventDefault();
    scheduleNewExport(buildingId, emails);
  };
  // Only fetch the export status when buildingId is available
  const exportStatusDt = useQuery(
    ['exportStatus', buildingId],
    () => getExportStatus(buildingId),
    {
      enabled: !!buildingId,
    },
  );
  const isExportScheduled = exportStatusDt?.data?.data?.status === 'PENDING';
  const commission = React.useMemo(
    () => [
      {
        Header: t('registre.commisiondesecu'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.resquise'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.prescRestantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const organisme = React.useMemo(
    () => [
      {
        Header: t('registre.RapportOrganismes'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const technicien = React.useMemo(
    () => [
      {
        Header: t('registre.techie'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.interventionDate'),
        accessor: 'report.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const formation = React.useMemo(
    () => [
      {
        Header: `${t('trainings.date_title')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          format(new Date(date), 'dd/MM/yyyy')
        ),
      },
      {
        Header: `${t('trainings.company')}`,
        accessor: 'society',
        Cell: ({
          row: {
            original: {
              speakerName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? speakerName || t('add_training.house_keeping')
            : '-'}`
        ),
      },
      {
        Header: `${t('trainings.fullname')}`,
        accessor: 'fullname',
        Cell: ({
          row: {
            original: {
              firstName, lastName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? '-'
            : `${firstName} ${lastName}`}`
        ),
      },
      {
        Header: `${t('trainings.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          `${type === TRAINING_TYPES?.FIRE_SAFETY_PREVENTION
            ? t('add_training.fire_safety_prevention')
            : t('add_training.maintenance_manager')}`
        ),
      },
      {
        Header: `${t('trainings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          `${name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS ? t('add_training.handling_emergency_means') : ''}
          ${name === TRAINING_NAMES.EVACUATION ? t('add_training.evacuation') : ''}
          ${name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION
            ? t('add_training.designated_employees_evacuation')
            : ''
          }
          ${name === TRAINING_NAMES.UNANNOUNCED_EXERCISE ? t('add_training.unannounced_exercise') : ''}
          ${name === TRAINING_NAMES.SSI ? t('add_training.ssi') : ''}
          ${name === TRAINING_NAMES.INTERNAL ? t('add_training.internal') : ''}
          ${name === TRAINING_NAMES.SSIAP ? t('add_training.ssiap') : ''}
          ${name === TRAINING_NAMES.SST ? t('add_training.sst') : ''}
          ${name === TRAINING_NAMES.ELECTRICAL_CLEARANCE ? t('add_training.electrical_clearance') : ''}`
        ),
      },
      {
        Header: `${t('trainings.headcount')}`,
        accessor: 'headcount',
      },
      {
        Header: `${t('trainings.person_in_charge')}`,
        accessor: 'personInCharge',
      },
      {
        Header: `${t('trainings.document')}`,
        accessor: 'documentFile',
        Cell: ({ row: { original: { documentFile, documentFileName, id } } }) => (
          documentFile !== null && (
            <Button
              type="button"
              className="action"
              label={(
                <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
              )}
              title={t('trainings.download')}
              onClick={() => docfile(id, documentFileName, 'training')}
            />
          )
        ),
      },
    ],
  );
  const regie = React.useMemo(
    () => [
      {
        Header: t('registre.regieName'),
        accessor: 'interventionTitle',
      },
      {
        Header: t('registre.NbrRepport'),
        accessor: 'report?.reference',
        Cell: ({ row }) => {
          if (row?.original?.report?.reference !== null) {
            return row?.original?.report?.reference;
          }
          return '-';
        },
      },
      {
        Header: t('registre.interventioninterneDate'),
        accessor: 'report?.reportDate',
        Cell: ({ row }) => {
          if (row?.original?.report?.reportDate === null) {
            return '-';
          }
          return new Date(row?.original?.report?.reportDate).toLocaleDateString();
        },
      },
      {
        Header: `${t('registre.report')}`,
        accessor: 'report.reportFileName',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'REPORT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.view')}
                  onClick={() => docfile(row?.original?.report?.id, row?.original?.report?.reportFileName, 'report')}
                />
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.levées'),
        accessor: 'liftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#158974' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.liftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obslevee')}
                  onClick={() => navigate(`/Observations/lifted/${row?.original?.report?.id}`)}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
      {
        Header: t('registre.restantes'),
        accessor: 'unliftedObservationsCount',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'OBSERVATION_LIST' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  label={(
                    <div className="fa-layers fa-fw" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#e74c3c' }} transform="grow-15" />
                      <span
                        className={cn(['fa-layers-text fa-inverses', styles.strong])}
                        data-fa-transform="shrink-11 left-20 up-2"
                      >
                        {row?.original?.unliftedObservationsCount}
                      </span>
                    </div>
                  )}
                  type="button"
                  className="action edit"
                  title={t('reports.obsrestante')}
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    } else {
                      navigate(`/Observations/remaining/${row?.original?.report?.id}`);
                    }
                  }}
                >
                  {' '}
                </Button>
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  return (
    <>
      <Layout
        title="Registre"
        layout="table"
        queryError={
          getInputsToCompleteQuery?.error
          || getBuildingsQuery?.error
          || getRegistreListQuery?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          <div className={cn(['row', styles.row])}>
            <h1 className="title">{t('registre.title')}</h1>
          </div>
        </header>
        {buildingId ? (
          <>
            <div className={cn(['row', styles.download])}>
              <button
                className="add"
                type="button"
                onClick={() => docfile(buildingId, 'registre')}
              >
                <FontAwesomeIcon icon={faFileArrowDown} />
                {' '}
                {t('registre.download')}
              </button>
            </div>
            <div className={cn(['row', styles.download])}>
              <button
                className={`${isExportScheduled ? 'disabled-btn' : 'add'}`}
                type="button"
                onClick={handleDownloadClick}
                disabled={isExportScheduled}
              >
                <FontAwesomeIcon icon={faFileArrowDown} />
                {' '}
                {t('registre.fullDownload')}
              </button>
            </div>
          </>
        ) : null}
        <div className={styles.filters}>
          <div className={styles.filtersToComplete}>
            {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
              <Button
                type="button"
                label={`${building.name}`}
                className={cn([styles.filter, buildingId === building.id
                  ? styles.active
                  : '', 'shadow-md'])}
                onClick={() => handleBuilding(building?.id)}
              />
            ))}
          </div>
        </div>
        <div className={styles.shadow}>
          {Registres?.data?.safetyCommittee.length >= 1
            ? (
              <div className={styles.table}>
                <Table
                  columns={commission}
                  isLoading={getRegistreListQuery.isLoading}
                  data={Registres?.data?.safetyCommittee || []}
                  hasSort
                />
              </div>
            ) : null }
          <div className={styles.table}>
            <Table
              columns={organisme}
              isLoading={getRegistreListQuery.isLoading}
              data={Registres?.data?.approvedBody || []}
              hasSort
            />
          </div>
          <div className={styles.table}>
            <Table
              columns={technicien}
              isLoading={getRegistreListQuery.isLoading}
              data={Registres?.data?.qualifiedTechnician || []}
              hasSort
            />
          </div>
          {Registres?.data?.internalManagement?.length >= 1
            ? (
              <div className={styles.tableRegie}>
                <Table
                  columns={regie}
                  isLoading={getRegistreListQuery.isLoading}
                  data={Registres?.data?.internalManagement || []}
                  hasSort
                />
              </div>
            ) : null }
          <div className={styles.tableFormations}>
            <Table
              columns={formation}
              isLoading={getRegistreListQuery.isLoading}
              data={Registres?.data?.training || []}
              hasSort
            />
          </div>
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <form onSubmit={handleSubmitExportDemand}>
          <Input
            label="Email(s)"
            type="string"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder={t('registry.receiveremail')}
            required
          />
          <div className={styles.text}>
            <small>{t('share.destinataire_help2')}</small>
          </div>
          <br />
          <Button
            type="submit"
            label={t('contact.send')}
            className="add"
          />
        </form>
      </Modal>
    </>
  );
}

export default Registre;

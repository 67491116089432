import api from './_api';

/**
 * GET - Get vesion list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 *  */
export const GetVersion = () => api.get('/version/back');

/**
 * GET - Get Vehicle list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 *  */
export const getVersionsFront = () => api.get('/version/front');

export const appVersion = '3.1.152';
export const appDate = '2025-01-14';
